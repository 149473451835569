import React from 'react';
import styled from 'styled-components';
import {Descriptions, Button} from 'antd';

const Descriptions1ItemProps = {
  span: 3,
  labelStyle: {
    width: 130,
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'center;',
  },
};

const Descriptions2ItemProps = {
  span: 1.5,
  labelStyle: {
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'center;',
  },
};

const Descriptions3ItemProps = {
  span: 1.5,
  labelStyle: {
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'center;',
  },
};

const Descriptions4ItemProps = {
  span: 3,
  labelStyle: {
    width: 130,
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'center;',
  },
};

const Descriptions1Title = '本公會第三十屆理事、監事';
const Descriptions2Title = '專門委員會-主任委員';
const Descriptions3Title = '專業領域委員會-主任委員';
const Descriptions4Title = '本公會秘書處工作人員一覽表';

const Descriptions1Data = [
  {label: '理事長', names: ['張志朋律師']},
  {
    label: '常務理事',
    names: [
      '徐頌雅律師',
      '林光彥律師',
      '鄭凱鴻律師',
      '鍾元珧律師',
      '蔡毓貞律師',
      '羅婉婷律師',
    ],
  },
  {
    label: '常務監事',
    names: ['高鳳英律師', '謝良駿律師'],
  },
  {
    label: '理事',
    names: [
      '朱芳君律師',
      '張安婷律師',
      '楊晴翔律師',
      '黃盈舜律師',
      '沈以軒律師',
      '方瑋晨律師',
      '陳耀南律師',
      '王韋傑律師',
      '盧偉銘律師',
      '陳怡妃律師',
      '林文凱律師',
      '林彥宏律師',
      '廖維達律師',
      '黃任顯律師',
    ],
  },
  {
    label: '監事',
    names: [
      '周宇修律師',
      '吳至格律師',
      '陳奕廷律師',
      '王孟如律師',
      '謝孟羽律師',
    ],
  },
];

const Descriptions2Data = [
  {label: '律師倫理風紀委員會', names: ['張志朋律師']},
  {label: '會員權利維護委員會', names: ['林光彥律師']},
  {label: '律師在職進修委員會', names: ['廖郁晴律師']},
  {label: '律師公益事務委員會', names: ['林詩梅律師']},
  {label: '人權委員會', names: ['翁國彥律師']},
  {label: '司法改革委員會', names: ['唐玉盈律師']},
  {label: '法律扶助委員會', names: ['朱芳君律師']},
  {label: '國會及公共事務委員會', names: ['陳一銘律師']},
  {label: '法治教育委員會', names: ['吳威廷律師']},
  {label: '會刊編輯委員會', names: ['王孟如律師']},
  {label: '出版委員會', names: ['何念修律師']},
  {label: '學術交流委員會', names: ['陳琬渝律師']},
  {label: '律師轉任法官審查委員會', names: ['范瑞華律師']},
  {label: '網域名稱爭議處理委員會', names: ['鍾文岳律師']},
  {label: '福利委員會', names: ['鄭凱鴻律師']},
  {label: '律師與人文委員會', names: ['楊晴翔律師']},
  {label: '法律文學藝術委員會', names: ['李柏青律師']},
  {label: '體育委員會', names: ['林文凱律師']},
  {label: '機構律師委員會', names: ['許瀞心律師']},
  {label: '資深律師委員會', names: ['吳西源律師']},
  {label: '新進律師委員會', names: ['黃   俐律師']},
  {label: '性別平等委員會', names: ['鄧   傑律師']},
  {label: '性騷擾申訴處理委員會', names: ['李晏榕律師']},
  {label: '國際事務委員會', names: ['陳逸竹律師']},
  {label: '中國事務委員會', names: ['林啟瑩律師']},
  {label: '公職律師委員會', names: ['洪偉勝律師']},
  {label: '資訊委員會 ', names: ['張本立律師']},
  {label: '媒體及公關委員會', names: ['黃盈舜律師']},
];

const Descriptions3Data = [
  {label: '憲法委員會', names: ['簡凱倫律師']},
  {label: '律師制度及律師業務委員會', names: ['林彥宏律師']},
  {label: '行政法委員會', names: ['劉昌坪律師']},
  {label: '民事法委員會', names: ['方南山律師']},
  {label: '民事程序法委員會', names: ['魏潮宗律師']},
  {label: '刑事法委員會', names: ['蕭奕弘律師']},
  {label: '刑事程序法委員會', names: ['林俊宏律師']},
  {label: '犯罪被害人保護委員會', names: ['宋一心律師']},
  {label: '勞動法委員會', names: ['沈以軒律師']},
  {label: '家事法委員會', names: ['莊喬汝律師']},
  {label: '青少年及兒童法委員會', names: ['柯萱如律師 ']},
  {label: '環境法委員會', names: ['郭鴻儀律師']},
  {label: '工程法委員會', names: ['孫丁君律師']},
  {label: '稅法委員會', names: ['王萱雅律師']},
  {label: '公司治理及企業併購委員會', names: ['羅名威律師']},
  {label: '金融財經法委員會', names: ['黃燕枝律師']},
  {label: '商事法委員會', names: ['盧偉銘律師']},
  {label: '競爭法委員會', names: ['陳信宏律師']},
  {label: '智慧財產委員會', names: ['林佳瑩律師']},
  {label: '專利法委員會', names: ['謝祥揚律師']},
  {label: '商標法委員會 ', names: ['吳宗樺律師']},
  {label: '創新科技委員會', names: ['王琍瑩律師']},
  {label: '娛樂法及運動法委員會', names: ['劉俊霙律師']},
  {label: '文化藝術法委員會', names: ['李佩昌律師']},
  {label: '數位服務與資料保護委員會', names: ['施汝憬律師']},
  {label: '司法鑑定委員會', names: ['羅士翔律師']},
  {label: '醫事暨衛生委員會', names: ['黃鈺媖律師']},
  {label: '生技藥事法委員會', names: ['張濱璿律師']},
  {label: '國際法委員會', names: ['楊貴智律師']},
  {label: '移民法委員會', names: ['劉又禎律師']},
  {label: '跨境交易及爭端處理委員會', names: ['王韋傑律師']},
  {label: '消費者債務清理委員會', names: ['林永頌律師']},
  {label: '能源法委員會', names: ['陳孟秀律師']},
  {label: '通訊傳播法委員會', names: ['方瑋晨律師']},
  {label: '永續發展委員會', names: ['沈妍伶律師']},
  {label: '信託及保險法制委員會', names: ['游淑君律師']},
];

const Descriptions4Data = [
  {label: '秘書長', names: ['洪國勛律師']},
  {label: '副秘書長', names: ['廖郁晴律師']},
  {label: '文書組', names: ['副秘書長 廖堃安律師', '主任 王裕文律師']},
  {
    label: '資訊組',
    names: ['副秘書長 劉懿嫻律師', '主任 羅亦成律師', '主任 鄭詠芯律師'],
  },
  {
    label: '媒體公關組',
    names: ['副秘書長 柯佩吟律師', '副秘書長 李惠暄律師'],
  },
  {
    label: '總務組',
    names: ['副秘書長 莫詒文律師', '副秘書長 林宜璇律師', '主任 王仲軒律師'],
  },
  {
    label: '會計組',
    names: ['副秘書長 吳奕璇律師', '副秘書長 陳唯宗律師', '主任 吳家豪律師'],
  },
  {
    label: '涉外事務組',
    names: [
      '副秘書長 黃   傑律師',
      '主任 陳彥君律師',
      '主任 張仲宇律師',
      '主任 吳彬詣律師',
    ],
  },
  {label: '行政圖書組', names: ['副秘書長 莊佳叡律師', '主任 柯萱如律師']},
  {
    label: '活動組',
    names: ['副秘書長 陳琮勛律師', '副秘書長 廖經晟律師', '主任 黃冠嘉律師'],
  },
  {
    label: '會員業務發展支援',
    names: ['副秘書長 張恆嘉律師', '主任 林芝羽律師'],
  },
  {label: '執行秘書', names: ['羅娟娟']},
  {
    label: '法務企畫部',
    names: [
      '專職主任 施南瑄律師',
      '專職主任 黃婕語律師',
      '專職主任 林宜家律師',
      '研究員 黃宏宜',
      '研究員 張子芸',
    ],
  },
  {
    label: '文書部',
    names: [
      '資深專員 潘靜怡',
      '專員 陳俐蓁',
      '專員 張弘宜',
      '專員 李保儀',
      '專員 陳桂芬',
    ],
  },
  {
    label: '總務部',
    names: [
      '資深專員 吳淑瑜',
      '專員 張瑜庭',
      '專員 黃聖惠',
      '專員 錡丞暉',
      '事務員 曹品晞',
    ],
    longNames: [
      '專員 林誼蘋（新北地院律師休息室）',
      '事  務  員 李佳真（高院民事庭大廈律師休息室）',
      '事  務  員 温心華（台北地院律師休息室）',
      '事  務  員 邱麗美（士林地院內湖辦公室律師休息室）',
      '事  務  員 林秀玲（高院刑事庭大廈律師休息室）',
      '事  務  員 伍珮旋（士林地院律師休息室）',
    ],
  },
  {
    label: '財務會計部',
    names: ['資深專員 李淑蘋', '資深專員 歐惠純', '專員 劉惠玲'],
  },
  {label: '圖書資訊室', names: ['專員 王冠智', '專員 黃雅嵐']},
  {label: '媒體公關組', names: ['資深專員 吳淑瑜（兼）']},
];

function Team(props) {
  return (
    <Wrapper>
      <div className="container">
        <div className="text-content">
          <p>
            1947年台北律師公會成立時，會員僅有34人，迄2022年止，會員總數已逾8000人，約占全國執業律師總數之7成，為全國會員人數最多的地方律師公會。
          </p>

          <p>
            台北律師公會之組織原即為理事長制，嗣一度則改為常務理事輪值制，其後，台北律師公會於1990年4月8日，依據人民團體組織法再度改為理事長制，並依據章程規定，置理事21人，互選常務理事7人，理事長由21位理事就7位常務理事中選舉產生；另台北律師公會置監事7人，互選常務監事2人。
          </p>
        </div>
        <div className="photo-content">
          <img
            alt="photo1"
            className="photo1"
            src="https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/201899-1-1680860973"
          />
          <p>律師節會員大會(2018.09.09)</p>
        </div>
      </div>

      <div className="container">
        <div className="text-content">
          <p>
            為促進會務發展及法律專業的提升，本公會於理事會下設有數十個專門委員會及專業領域委員會，各委員會置主任委員1人、副主任委員及委員若干人，均由理事會就會員中聘任之。委員會如律師倫理風紀委員會職司會員紀律問題維持，司法改革委員會負責推動並監督國家司法制度及品質的提升，法律扶助委員會規劃提供對弱勢族群的法律服務，法治教育委員會深耕社會的法治教育等；專業領域委員會則依國家社會經濟之發展，以及法律制度之演進，先後分別成立有憲法、行政法、民事法、刑事法、商事及財經法等傳統法領域的委員會，以及有關性別平等、青少年及兒童問題、消費者債務清理、犯罪被害人保護等社會議題研究及社運推動的委員會，更有競爭法、娛樂法及運動法、智財及創新科技等新興法領域研究之委員會。（各委員會列表如下）
          </p>
          <p>
            為推動會務，理事會下尚有秘書處，秘書處置秘書長1人、副秘書長及主任若干人，由理事會就會員中聘任之，均為兼職，且為無給職，負責督導專任會務工作人員執行事務；秘書處之專任會務工作人員，設文書部、總務部、財務會計部及法務企畫部，聘有執行秘書、資深專員、專員、事務員、專職律師、研究員等若干人，分工處理文書、總務、會計、圖書資訊、媒體公關、法務等行政事務。
          </p>
        </div>
      </div>

      <div className="container">
        <div className="photo-content">
          <img
            alt="photo2"
            className="photo2"
            src="https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/DSC02830-1705470366"
          />
          <p>第30屆共識營活動</p>
        </div>
      </div>

      <h2 className="title">公會組織與人事</h2>
      <div className="container">
        <div className="photo-content">
          <img
            alt="photo3"
            className="photo3"
            src="https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/中文-1680769257-1710128801"
          />
        </div>
      </div>

      <h2 className="title">{Descriptions1Title}</h2>
      <div className="table-content">
        <Descriptions bordered>
          {Descriptions1Data.map((item) => (
            <Descriptions.Item label={item.label} {...Descriptions1ItemProps}>
              <div className="grid-wrapper">
                {item.names.map((name) => (
                  <div>{name}</div>
                ))}
              </div>
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>

      <h2 className="title">{Descriptions2Title}</h2>
      <div className="table-content">
        <Descriptions bordered>
          {Descriptions2Data.map((item) => (
            <Descriptions.Item label={item.label} {...Descriptions2ItemProps}>
              <div className="grid-wrapper">
                {item.names.map((name) => (
                  <div>{name}</div>
                ))}
              </div>
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>

      <h2 className="title">{Descriptions3Title}</h2>
      <div className="table-content">
        <Descriptions bordered>
          {Descriptions3Data.map((item) => (
            <Descriptions.Item label={item.label} {...Descriptions3ItemProps}>
              <div className="grid-wrapper">
                {item.names.map((name) => (
                  <div>{name}</div>
                ))}
              </div>
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>

      <Button
        type="link"
        size="large"
        style={{marginTop: 10, marginBottom: 10}}
        href="https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/會員加入委員會申請書_1120617-2-1698204289"
        rel="noreferrer">
        會員加入委員會申請書
      </Button>

      <h2 className="title">{Descriptions4Title}</h2>
      <div className="table-content">
        <Descriptions bordered>
          {Descriptions4Data.map((item, index) => {
            return (
              <Descriptions.Item label={item.label} {...Descriptions4ItemProps}>
                <div className="grid-long-wrapper">
                  {item.names.map((name) => (
                    <div>{name}</div>
                  ))}
                </div>
                {item.longNames && item.longNames.length > 0 && (
                  <div style={{borderRight: 'none', marginTop: 15}}>
                    {item.longNames.map((name) => (
                      <div
                        style={{
                          marginTop: 10,
                          paddingLeft: 8,
                          paddingRight: 8,
                          fontSize: 16,
                        }}>
                        {name}
                      </div>
                    ))}
                  </div>
                )}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    display: flex;

    & > .text-content {
      padding: 5px;
      flex: 1;

      & > p {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 2;
      }
    }

    & > .photo-content {
      padding: 5px;

      & > p {
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
      }

      & > .photo1,
      .photo2,
      .photo3 {
        width: 100%;
        height: auto;
      }
    }
  }

  & > .table-content {
    flex: 1;

    .grid-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, 110px);
      gap: 12px 0px;

      & > div {
        padding: 0px 15px 0px 10px;
        font-size: 16px;
        text-align: center;
        border-right: 1px solid lightgray;

        :last-child {
          border-right: none;
        }
      }
    }

    .grid-long-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, 200px);
      gap: 12px 0px;

      & > div {
        padding: 0px 8px 0px 10px;
        font-size: 16px;
        border-right: 1px solid lightgray;

        :last-child {
          border-right: none;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    & > .container {
      flex-direction: column;

      & > .photo-content {
        margin: 0 auto;
      }
    }
  }

  .title {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    bottom: 30px;
  }
`;

export default Team;
